<template>
    <div class="bbb">
        <van-nav-bar
        title="关注公众号问诊更方便"
        @click-left="onClickLeft"
        />
        <img   class="wechatImg" src="../assets/image/wxgongzonghao.png" alt="">
   
    </div>
  </template>
  <script>
 
  export default {
    data() {
      return {
  
      }
    },
    methods: {
        onClickLeft(){
            this.$router.back(-1)
        }
    },
    created() {
      
    },
    mounted(){
   

    }
  }
  </script>
  <style scopde lang="less">
    .wechatImg{
        width: 100%;
    }
  </style>